// Asynchronous loader for external scripts
var loadScript = function (src) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.async = true;
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
};
export default loadScript;
