import loadScript from "./loadScript";
var imaSdkSrc = "//imasdk.googleapis.com/js/sdkloader/ima3.js";
var pendingPromise = null;
var promiseFinished = function () {
    pendingPromise = null;
};
var loadImaSdk = function () {
    var w = window;
    if (w.google && w.google.ima) {
        return Promise.resolve(w.google.ima);
    }
    if (pendingPromise) {
        return pendingPromise;
    }
    pendingPromise = loadScript(imaSdkSrc).then(function () { return w.google.ima; });
    pendingPromise.then(promiseFinished).catch(promiseFinished);
    return pendingPromise;
};
export default loadImaSdk;
